import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Link, Route, Routes } from 'react-router-dom';
import Navbar from './components/common/header/navbar';
import Home from './components/home/home';
import Footer from './components/common/footer/footer';
import Network_Security from './components/products/network-security/network-security';
import Multi_cloud from './components/products/multi-cloud/multi-cloud';
import Network_intelligence from './components/products/network-intelligence/network-intelligence';
import About_us from './components/about_us/about_us';
import Contact from './components/contact/contact';
import ScrollT from './components/common/STTP/scroll_Top';
import STTopARROW from './components/common/Scroll_To_Top_Arrow/PP_STTop';
import PrivacyPolicy from './components/privacy_policy/PrivacyPolicy';
import CookieConsent from "react-cookie-consent";
import Endpont_Security from './components/products/endpoint-security/endpoint-security';
import Identity_Security from './components/products/identity-security/identity-security';
import Digitalrisk from './components/products/digital-risk/digital-risk';
import Cloud_Security from './components/products/cloud-security/cloud-security';

function App() {
  return (

    <BrowserRouter>
    <div className="App">
     <Navbar />

     <CookieConsent
      data-aos="fade-down"
                    data-aos-delay="150"
                    data-aos-easing="ease-in-out"   
                    data-aos-once = "true"
       className="Cookie_CS"
        buttonText="Accept"
        cookieName="Consent"
        style={{ background: "rgb(0,0,0,0.85)", fontSize: "1.1rem",fontWeight:'lighter', width:"100%",
          alignItems: "center", justifyContent:"center" , textAlign: "center",
          position: "absolute", 
          margin: "15px auto !important",
          margin: "0 auto",
          zIndex: "15",  
          position: "fixed",
          display: "relative !important",
          // left: "50%",
          bottom: '0',
          paddingTop: "10px",
          // transform: "translate(-50%,0%)",
          fontFamily: "'Questrial', sans-serif"
        }}
        buttonStyle={{ backgroundColor: "var(--color1)", fontSize: "1.4rem", color: "white", 
        fontWeight: "500", marginBottom: "20px", borderRadius: "5px", textAlign: "center",width: '150px', height: '50px'

         }}
        expires={150}
      >
       This website uses cookies, in addition to the cookies that are strictly necessary to operate this website we use the following types of cookies to improve your experience and our services: Functional cookies to enhance your experience (e.g. remember settings), Performance cookies to measure the website's performance and improve your experience.


       For more information, please read our &nbsp;
       <Link style={{color:'white',fontWeight:'bolder'}} to='/privacy-policy'>
        privacy policy       </Link><br/>{" "}
      </CookieConsent>
     <Routes>

      <Route path='/' element={<Home />} />
      <Route path ='/network-security' element={<Network_Security />} />
      {/* <Route path ='/multi-cloud' element={<Multi_cloud />} /> */}
      <Route path ='/endpoint-security' element={<Endpont_Security />} />
      <Route path ='/identity-security' element={<Identity_Security />} />
      <Route path ='/digital-risk-compliance' element={<Digitalrisk />} />
      <Route path ='/cloud-security' element={<Cloud_Security />} />

      {/* <Route path ='/network-data-intelligence' element={<Network_intelligence />} /> */}
      <Route path ='/about-us' element={<About_us />} />
      <Route path ='/contact-us' element={<Contact />} />
      <Route path ='/privacy-policy' element={<PrivacyPolicy />} />


     </Routes>
 <ScrollT />
 <STTopARROW/>
     <Footer />
    </div>
    </BrowserRouter>
  );
}

export default App;
