import './network-security.css'

import { Button, Container, Grid, Paper, Typography } from '@mui/material'
import React from 'react'
import Expert from '../../common/expert/expert'


export default function Network_Security() {
    
    


    
    
    
    
  const network_data=[{
    head:"NGFW security audit ",
    data:"Thorough examination & evaluation of a network's security measures.",
    img:require('../../../images/network_security.webp')
  },{
    head:"NGFW Implementation",
    data:'Implementing a Next-Generation Firewall (NGFW) as per the Validated Design Guide.',
    img:require('../../../images/management.jpg')
  },
  {
    head:"Consulting (AS-is and Roadmap)",
    data:'Reviewing the current state of Security Posture and assisting with the Roadmap.',
    img:require('../../../images/security.jpg')
  },
  {
    head:"NGFW Training (PA/Fortinet/CheckPoint/Cisco etc)",
    data:'Training by the experts who have relevant industry experience.',
    img:require('../../../images/network_security.webp')
  },
  {
    head:"ZTNA/ZPA/SD-WAN Consulting (AS-is & Roadmap)",
    data:'Reviewing the current state of Security Posture and assisting with the Roadmap.',
    img:require('../../../images/management.jpg')
  },
  {
    head:"ZTNA/ZPA/SD-WAN  Implementation/Deployment",
    data:'Implementing SSE as per the Validated Design Guide.',
    img:require('../../../images/security.jpg')
  },


]
  
  return (


<Grid container>

<Container className='home_top_main_comp'>

 <Grid container>   
<Grid container data-aos='fade-right' sm={12} xs={12}>

    <Grid item sm={2} xs={2}>
<img src={require('../../../images/logo.png')} className='products_N_S_logo' />
    </Grid>
    <Grid item alignContent={'center'} sm={10} xs={10}>
    <Typography  className='home_top_txt1'>
    NETWORK SECURITY USE CASES

    </Typography> 
    </Grid>
   
</Grid>
<Grid item sm={12} xs={12}>
    <Typography data-aos='fade-right'  className='home_top_txt2'>
    GT Cyber for 
Network Security   </Typography>
</Grid>

<Grid item sm={12} xs={12}>
    <Typography data-aos='fade-right' className='home_top_txt3'>
    Proactively identify non-compliance and vulnerabilities before a breach occurs</Typography>
</Grid>

<Grid item style={{paddingBottom:'5vh'}} sm={12} xs={12}>
<Button data-aos='fade-right'  onClick={()=>window.location.href='/contact-us'}  className='home_below_ready_btn1'>
       Request a demo
    </Button>  
    </Grid>
    </Grid>


    {network_data.map((val,idx)=>
    idx%2===0?<Grid data-aos='zoom-in-down' container style={{padding:'5vh 0px'}} >

    <Paper className='products_N_S_ppr'>
            
    <Grid container height={'100%'} sm={12} xs={12}>
    
            <Grid container  sm={6} xs={12}>
    <img  src={val.img}  className='products_N_S_ppr_img' />
            </Grid>
            <Grid container alignContent={'flex-start'} sm={6} xs={12}>
                <Grid item sm={12} xs={12}>
                    <Typography className='products_N_S_ppr_head'>
                    {val.head}
    
                    </Typography>
                </Grid>
                <Grid item sm={12} xs={12}>
                    <Typography className='products_N_S_ppr_desc'>
{val.data}                    </Typography>
                </Grid>
            </Grid>
            </Grid>
            </Paper>
        </Grid>:<Grid data-aos='zoom-in-down' container style={{padding:'5vh 0px'}} >

<Paper className='products_N_S_ppr'>
        
<Grid container height={'100%'} sm={12} xs={12}>

       
        <Grid container className='products_N_S_cmp' alignContent={'flex-start'} sm={6} xs={12}>
            <Grid item sm={12} xs={12}>
                <Typography className='products_N_S_ppr_head2'>
                {val.head}


                </Typography>
            </Grid>
            <Grid item sm={12} xs={12}>
                <Typography className='products_N_S_ppr_desc2'>
{val.data}           
     </Typography>
            </Grid>
        </Grid>
        <Grid container  sm={6} xs={12}>
<img src={val.img}  className='products_N_S_ppr_img2' />
        </Grid>
        
        </Grid>
        </Paper>
    </Grid>
    )}
    
{/*     
    <Grid data-aos='zoom-in-down' container style={{padding:'5vh 0px'}} >

<Paper className='products_N_S_ppr'>
        
<Grid container height={'100%'} sm={12} xs={12}>

        <Grid container  sm={6} xs={12}>
<img src={require('../../../images/security.jpg')}  className='products_N_S_ppr_img' />
        </Grid>
        <Grid container alignContent={'flex-start'} sm={6} xs={12}>
            <Grid item sm={12} xs={12}>
                <Typography className='products_N_S_ppr_head'>
                Security Posture Management and Zero Trust


                </Typography>
            </Grid>
            <Grid item sm={12} xs={12}>
                <Typography className='products_N_S_ppr_desc'>
                Always current, at-a-glance micro-segmentation verification. Security posture verification for hybrid multi-cloud environments
                </Typography>
            </Grid>
        </Grid>
        </Grid>
        </Paper>
    </Grid>
    <Grid data-aos='zoom-in-down' container style={{padding:'5vh 0px'}} >

<Paper className='products_N_S_ppr'>
        
<Grid container height={'100%'} sm={12} xs={12}>

        <Grid container  sm={6} xs={12}>
<img  src={require('../../../images/network_security.webp')}  className='products_N_S_ppr_img' />
        </Grid>
        <Grid container alignContent={'flex-start'} sm={6} xs={12}>
            <Grid item sm={12} xs={12}>
                <Typography className='products_N_S_ppr_head'>
                Incident Response

                </Typography>
            </Grid>
            <Grid item sm={12} xs={12}>
                <Typography className='products_N_S_ppr_desc'>
                Know your attack surface and device “blast radius” in an instant to speed remediation  
                </Typography>
            </Grid>
        </Grid>
        </Grid>
        </Paper>
    </Grid>
    <Grid data-aos='zoom-in-down' container style={{padding:'5vh 0px'}} >

<Paper className='products_N_S_ppr'>
        
<Grid container height={'100%'} sm={12} xs={12}>

       
        <Grid container className='products_N_S_cmp' alignContent={'flex-start'} sm={6} xs={12}>
            <Grid item sm={12} xs={12}>
                <Typography className='products_N_S_ppr_head2'>
                Vulnerability Management


                </Typography>
            </Grid>
            <Grid item sm={12} xs={12}>
                <Typography className='products_N_S_ppr_desc2'>
                Know your network is vulnerability free with mathematical certainty  
                </Typography>
            </Grid>
        </Grid>
        <Grid container  sm={6} xs={12}>
<img src={require('../../../images/management.jpg')}  className='products_N_S_ppr_img2' />
        </Grid>
        
        </Grid>
        </Paper>
    </Grid>
    <Grid data-aos='zoom-in-down' container style={{padding:'5vh 0px'}} >

<Paper className='products_N_S_ppr'>
        
<Grid container height={'100%'} sm={12} xs={12}>

        <Grid container  sm={6} xs={12}>
<img src={require('../../../images/security.jpg')}  className='products_N_S_ppr_img' />
        </Grid>
        <Grid container alignContent={'flex-start'} sm={6} xs={12}>
            <Grid item sm={12} xs={12}>
                <Typography className='products_N_S_ppr_head'>
                Security Posture Management and Zero Trust


                </Typography>
            </Grid>
            <Grid item sm={12} xs={12}>
                <Typography className='products_N_S_ppr_desc'>
                Always current, at-a-glance micro-segmentation verification. Security posture verification for hybrid multi-cloud environments
                </Typography>
            </Grid>
        </Grid>
        </Grid>
        </Paper>
    </Grid> */}


</Container>

{/* <Grid container style={{padding:'2vw'}}>

 <Grid data-aos='zoom-in-down' style={{padding:'2vw'}} item sm={4} xs={12}>
    <Paper className='below_products_N_S_ppr'>
    <Grid item sm={12} xs={12}>
    <img src={require('../../../images/network_security.webp')} className='below_products_N_S_ppr_img' />

    </Grid>
    <Grid item sm={12} xs={12}>
<Typography className='below_products_N_S_ppr_head'>The Game Changer - Network Digital Twin for Network Security with GT Cyber
</Typography>
    </Grid>
    <Grid item sm={12} xs={12}>
    <Typography className='below_products_N_S_ppr_desc'>Why are so many network security breaches hitting the headlines lately? Former State Department cybersecurity professional Matt Honea guides the XFD audience through the latest news-worthy exploitations of network vulnerabilities and how a network digital twin could have mitigated the disasters. Matt outlines the key steps to build a successful network security program and key areas that the GT Cyber platform directly supports.

Presented by Matt Honea, Head of Security and Compliance, and Mike Lossmann, Technical Product Marketing Manager. Recorded live at Security Field Day 10 on November 9, 2023 virtually</Typography>

    </Grid>
    
    </Paper>
    
    </Grid>   
    <Grid data-aos='zoom-in-down' style={{padding:'2vw'}} item sm={4} xs={12}>
    <Paper className='below_products_N_S_ppr'>
    <Grid item sm={12} xs={12}>
    <img src={require('../../../images/management.jpg')} className='below_products_N_S_ppr_img' />

    </Grid>
    <Grid item sm={12} xs={12}>
<Typography className='below_products_N_S_ppr_head'>
GT Cyber Demo: How a Digital Twin Transforms Network Security

</Typography>
    </Grid>
    <Grid item sm={12} xs={12}>
    <Typography className='below_products_N_S_ppr_desc'>Why are so many network security breaches hitting the headlines lately? Former State Department cybersecurity professional Matt Honea guides the XFD audience through the latest news-worthy exploitations of network vulnerabilities and how a network digital twin could have mitigated the disasters. Matt outlines the key steps to build a successful network security program and key areas that the GT Cyber platform directly supports.

    So how does it work? Our seasoned network engineer, Mike Lossmann pops open the GT Cyber application to walk the XFD audience through the full network digital twin functionality. Mike guides the delegates through intent-checking and blast radius exposure, security posture / zero trust and network vulnerabilities, network verification for layer 3 to layer 7, the discovery of rogue devices, and configuration validity all within one app.

Presented by Matt Honea, Head of Security and Compliance, and Mike Lossmann, Technical Product Marketing Manager. Recorded live at Security Field Day 10 on November 9, 2023 virtually.</Typography>

    </Grid>
    
    </Paper>
    
    </Grid>  
    <Grid data-aos='zoom-in-down' style={{padding:'2vw'}} item sm={4} xs={12}>
    <Paper className='below_products_N_S_ppr'>
    <Grid item sm={12} xs={12}>
    <img src={require('../../../images/security.jpg')} className='below_products_N_S_ppr_img' />

    </Grid>
    <Grid item sm={12} xs={12}>
<Typography className='below_products_N_S_ppr_head'>GT Cyber Wins 2022 Fortress Cyber Security Award

</Typography>
    </Grid>
    <Grid item sm={12} xs={12}>
    <Typography className='below_products_N_S_ppr_desc'>
"Our network digital twin provides a single source of truth across on-premises and multi-cloud environments to reduce the time it takes for security professionals to diagnose and remediate vulnerabilities while allowing them to prove compliance with mathematical certainty," said Chiara Regale, vice president of product and user experience at GT Cyber. 

"Being recognized as a Network Security Leader in the 2022 Fortress Cyber Security Awards is an incredible honor, as it validates that our efforts to give network and security professionals fast, easy access to actionable insights are valued by the industry and security professionals."</Typography>

    </Grid>
    
    </Paper>
    
    </Grid>  
</Grid>


<Grid container style={{padding:'2vw'}}>

 <Grid  data-aos='zoom-in-down'style={{padding:'2vw'}} item sm={4} xs={12}>
    <Paper className='below_products_N_S_ppr2'>
    <Grid item sm={12} xs={12}>
    <img src={require('../../../images/network_security.webp')} className='below_products_N_S_ppr_img' />

    </Grid>
    <Grid item sm={12} xs={12}>
<Typography className='below_products_N_S_ppr2_head'>The Game Changer - Network Digital Twin for Network Security with GT Cyber
</Typography>
    </Grid>
  
    
    </Paper>
    
    </Grid>   
    <Grid  data-aos='zoom-in-down' style={{padding:'2vw'}} item sm={4} xs={12}>
    <Paper className='below_products_N_S_ppr2'>
    <Grid item sm={12} xs={12}>
    <img src={require('../../../images/management.jpg')} className='below_products_N_S_ppr_img' />

    </Grid>
    <Grid item sm={12} xs={12}>
<Typography className='below_products_N_S_ppr2_head'>
GT Cyber Demo: How a Digital Twin Transforms Network Security

</Typography>
    </Grid>
  
    
    </Paper>
    
    </Grid>  
    <Grid data-aos='zoom-in-down' style={{padding:'2vw'}} item sm={4} xs={12}>
    <Paper className='below_products_N_S_ppr2'>
    <Grid item sm={12} xs={12}>
    <img src={require('../../../images/security.jpg')} className='below_products_N_S_ppr_img' />

    </Grid>
    <Grid item sm={12} xs={12}>
<Typography className='below_products_N_S_ppr2_head'>GT Cyber Wins 2022 Fortress Cyber Security Award

</Typography>
    </Grid>
   
    
    </Paper>
    
    </Grid>  
</Grid> */}


<Expert />
</Grid>

  )
}
