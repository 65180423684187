import { Grid, Typography } from '@mui/material'
import React from 'react'
import './footer.css'
import { NavLink } from 'react-router-dom'
import { MdFacebook } from 'react-icons/md'
import { AiFillInstagram } from 'react-icons/ai'
import { FaThreads } from 'react-icons/fa6'
import { IoLogoWhatsapp } from 'react-icons/io'

export default function Footer() {
  return (

    <Grid container>

        <Grid container style={{background:'var(--color1)',padding:'3vh 2vw',alignContent:'center'}}>
        <Grid item alignSelf={'center'} sm={3} xs={12}>
<img src={require('../../../images/logo.png')}className='footer_logo'  />
        </Grid>
        <Grid container sm={3} xs={12}>
           <Grid item sm={12} xs={12}>
            <Typography className='footer_head_main'>
                Product
            </Typography>
            </Grid> 
          
            <Grid item sm={12} xs={12}>
            <Typography  style={{paddingBottom:15,textAlign:'left'}}>
                <NavLink className='footer_sub  hvr-underline-from-left-white' to={'/network-security'}>
                Network Security
                </NavLink>
            </Typography>
            </Grid> 
            <Grid item sm={12} xs={12}>
            <Typography  style={{paddingBottom:15,textAlign:'left'}}>
            <NavLink className='footer_sub hvr-underline-from-left-white' to={'/endpoint-security'}>

            Endpoint Security
                </NavLink>
            </Typography>
            </Grid> 
            <Grid item sm={12} xs={12}>
            <Typography  style={{paddingBottom:15,textAlign:'left'}}>
            <NavLink className='footer_sub hvr-underline-from-left-white' to={'/identity-security'}>

               Identity Security
                </NavLink>
            </Typography>
            </Grid> 
            {/* <Grid item sm={12} xs={12}>
            <Typography  style={{paddingBottom:15,textAlign:'left'}}>
            <NavLink className='footer_sub hvr-underline-from-left-white' to={'/cloud-security'}>

               Cloud Security
                </NavLink>
            </Typography>
            </Grid> 
            <Grid item sm={12} xs={12}>
            <Typography  style={{paddingBottom:15,textAlign:'left'}}>
            <NavLink className='footer_sub hvr-underline-from-left-white' to={'/digital-risk-compliance'}>

            Digital Risk & Compliance
                </NavLink>
            </Typography>
            </Grid>  */}
            </Grid>
            <Grid container alignContent={'flex-start'} sm={3} xs={12}>
           <Grid item sm={12} xs={12}>
            <Typography className='footer_head_main'>
              Reach Us
            </Typography>
            </Grid> 
          
            {/* <Grid item style={{paddingBottom:15}} sm={12} xs={12}>
            <div  className="footer_sub2">
                        <i style={{paddingRight:10}}  className="fas fa-phone-alt "></i>
                        <a href="tel:+91-124-4941059"className="footer_sub2">
                        +91-124-4941059
                        </a>  
                    </div>
                
                    
            </Grid>  */}
            <Grid item sm={12} xs={12}>
            <div className="footer_sub2">
                        <i style={{paddingRight:10}}  className="fa fa-envelope"></i>
                        <a href="mailto:info@gtcvlabs.com"  className="footer_sub2">
                        info@gtcvlabs.com
                        </a>  
                    </div>
            </Grid> 
         
            </Grid>
            <Grid container alignContent={'flex-start'} sm={3} xs={12}>
           <Grid item sm={12} xs={12}>
            <Typography className='footer_head_main'>
                Company
            </Typography>
            </Grid> 
          
            <Grid item sm={12} xs={12}>
            <Typography  style={{paddingBottom:15,textAlign:'left'}}>
                <NavLink className='footer_sub  hvr-underline-from-left-white' to={'/about-us'}>
                About Us
                </NavLink>
            </Typography>
            </Grid> 
            <Grid item sm={12} xs={12}>
            <Typography  style={{paddingBottom:15,textAlign:'left'}}>
            <NavLink className='footer_sub hvr-underline-from-left-white' to={'/contact-us'}>

                Contact Us
                </NavLink>
            </Typography>
            </Grid> 
          
            </Grid>
            <div style={{width:'100%',textAlign:'center',padding:'1vh 0px'}}>
                            <hr  className='footer_separator' />
                            </div>

            <Grid container  alignItems={'center'} style={{padding:'1vw 3vw'}} sm={12} xs={12}>
<Grid item style={{width:'fit-content',display:'flex'}} sm={8} xs={12}>
<Typography className='footer_cpryt_txt'>
Copyright @2024. All Rights Reserved.
</Typography>
<Typography  style={{textAlign:'left'}}>
                <NavLink style={{}} className='footer_sub3 ' to={'/privacy-policy'}>
Privacy Policy             
   </NavLink>
            </Typography>
      
        </Grid>
        {/* <Grid item sm={2} xs={4}>
      
      
        </Grid> */}
        <Grid item sm={4} xs={12}>
        <div className={'phone-pad'}>
          {/* <h1 className='footer_link_title'>Follow us </h1> */}
          <div className='socials'>
            <MdFacebook />
            <AiFillInstagram />
            <FaThreads />
            <IoLogoWhatsapp />
          </div>
        </div>
      
        </Grid>

       
      
            </Grid>
    </Grid>
    </Grid>
  )
}
