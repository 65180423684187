import React from 'react'
import Home_top from './home_top/home_top'
import Home_bottom from './home_bottom/home_bottom'

export default function Home() {
  return (

    <>

<Home_top/>
<Home_bottom />
    </>

  )
}
