import { Button, Container, Grid, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import './home_bottom.css'
import Expert from '../../common/expert/expert'
import 'aos/dist/aos.css';
import Aos from 'aos';
export default function Home_bottom() {
  useEffect(() => {
    Aos.init()
   }, [])
  return (

    <Grid container className='home_below_main_comp'>


<Grid container style={{background:'var(--color1)',}}  sm={12} xs={12}>
<Grid container sm={6} xs={12}>
   <Grid item data-aos='zoom-in'  textAlign={'right'} sm={12} xs={12}>
    
    
    <img src={require('../../../images/mission.webp')} className='home_below_mis_img' />
    </Grid> 

</Grid>
<Grid container style={{padding:'2vh 2vw 0px'}} sm={6} xs={12}>
<Grid container sm={12} xs={12}>

<Grid item sm={12} xs={12}>

<Typography data-aos='fade-right'  className='home_below_mis_head'>
    OUR MISSION
</Typography>

</Grid>
<Grid item sm={12} xs={12}>

<Typography data-aos='fade-right'  className='home_below_mis_sub_head'>
Transforming networks to be agile, reliable and secure

</Typography>

</Grid>
<Grid item sm={12} xs={12}>

<Typography data-aos='fade-right'  className='home_below_mis_sub_head_desc'>
Empowering a safer digital world through innovative, adaptive, and resilient cybersecurity solutions that protect the integrity, confidentiality, and availability of information across all platforms and sectors.
</Typography>

</Grid>
<Grid container style={{padding:'2vh 1vw'}}>
<Grid container data-aos='zoom-in-up'  justifyContent='center'  sm={6} xs={6} >

 <div className='home_below_mis_num_comp'>  
<Grid item sm={12} xs={12}>

<Typography className='home_below_mis_num'>
80<b>%</b>
</Typography>

</Grid>
<Grid item sm={12} xs={12}>

<Typography className='home_below_mis_num_head'>
Audit time saved
</Typography>

</Grid>
</div> 
</Grid>
<Grid container data-aos='zoom-in-up'  justifyContent='center'  sm={6} xs={6} >

 <div className='home_below_mis_num_comp'>  
 <Grid item sm={12} xs={12}>

<Typography className='home_below_mis_num'>
10<b>+</b>
</Typography>

</Grid>
<Grid item sm={12} xs={12}>

<Typography className='home_below_mis_num_head'>
Vendor supported
</Typography>

</Grid>
</div> 
</Grid>
<Grid container data-aos='zoom-in-up'  justifyContent='center'  sm={6} xs={6} >

 <div className='home_below_mis_num_comp'>  
 <Grid item sm={12} xs={12}>

<Typography className='home_below_mis_num'>
5<b>+</b>
</Typography>

</Grid>
<Grid item sm={12} xs={12}>

<Typography className='home_below_mis_num_head'>
Industry awards
</Typography>

</Grid>
</div> 
</Grid>
<Grid container data-aos='zoom-in-up'  justifyContent='center'  sm={6} xs={6} >

 <div className='home_below_mis_num_comp'>  
 <Grid item sm={12} xs={12}>

<Typography className='home_below_mis_num'>
60<b>%</b>
</Typography>

</Grid>
<Grid item sm={12} xs={12}>

<Typography className='home_below_mis_num_head'>
App deploy time saved
</Typography>

</Grid>
</div> 
</Grid>


</Grid>
</Grid>

</Grid>


</Grid>

<Expert />
    </Grid>
  )
}
