import React from 'react'
// import ImageComponent from '../components/commons/ImageComponent'
import './PrivacyPolicy.css'
import { Container, Grid } from '@mui/material'

const PrivacyPolicy = () => {
  return (
    <div>
      {/* <ImageComponent
        heading={'Privacy Policy'}
        image_source={require('../components/Assets/privacy.jpeg')}
      /> */}

      <Grid container style={{textAlign:'left',marginTop:'20vh',marginBottom:'2vh'}}>
        <Container >
        <div className='wrapper-container'>
        <div
          className='pp-container'
          style={{
            backgroundColor: '#ebebeb',
            padding: '15px',
            borderRadius: '5px',
            border: '1px solid #d9d9d9',
            width: '100%',
            height: 'auto'
          }}
        >
          <h4>
            GT Cyber Virtual Labs
            <br />
            PRIVACY POLICY
          </h4>
          <p>
            GT Cyber Virtual Labs, its subsidiaries
            and affiliates (hereinafter referred to as "GT Cyber" "we") understand
            that your privacy is important to you, and it is equally important
            to us. We may process your personal data in the capacity of data
            controllers / body corporates. We are committed to respecting your
            privacy and protecting your personal data, which is any information
            that can identify you as an individual person.
          </p>
          <br />
          <p>
            This privacy policy describes your information that we collect
            through this website, how that information is used, maintained,
            shared, protected and how you can update it. It also applies to all
            personal data received by us from the European Economic Area ("EEA")
            in any format, including electronic or paper. We shall process your
            personal data in accordance with this Privacy Policy, unless such
            processing conflicts with the requirements of applicable law, in
            which case, applicable law will prevail.
          </p>
          <br />
          <p>
            By submitting your personal data to GT Cyber, you acknowledge that:
          </p>
          <ul>
            <li>
              a) You have read and understood this privacy policy and agree to
              the use of your personal data as set out here. Your personal data
              may be transferred and processed by business units, processes and
              systems in respective countries in which GT Cyber conducts its
              business and has dealings or other business relationships with
              third parties, for the purposes and in the manner specified in
              this policy.
            </li>
            <li>
              b) You are not obligated to provide any requested information to
              us but failing to do so may result in not being able to proceed
              with your requirement.
            </li>
            <li>
              c) All your representations are true and correct to the best of
              your knowledge and belief and you have not knowingly omitted any
              related information of an adverse nature{' '}
            </li>
            <li>
              d) Providing any inaccurate information may make you ineligible
              for availing our products, solutions, services or employment.
            </li>
          </ul>

          <h4>Personal Data We Collect </h4>

          <p>
            When you use the website of GT Cyber for contacting us for product or
            service-related query or to apply for any job openings, your
            personal data is collected by us. The types of personal data that
            are collected from you with respect to your enquiry includes your
            name, company name, contact details, address, and information about
            your device.
          </p>
          <p>
            If you apply for a job or opportunity as per your skills through
            this website, you will be asked to submit your resume. We will use
            the information in your resume to consider you for the job opening
            that you specify. We may also use the information in your resume to
            contact you regarding other opportunities, as per your skills
            advertised on this website.
          </p>
          <p>
            We may also collect your personal data from third parties, such as
            professional recruiting firms, your references, prior employers,
            GT Cyber employees who have interviewed you and employment background
            check providers, to the extent this is permitted by applicable law.
          </p>
          <p>
            We may also ask you for personal data in connection with other
            interactions you have with this website, including when you answer a
            survey or report a problem with this website or services offered on
            this website
          </p>
          <p>
            We may use a third-party service provider to assist us with some
            features of our website. Our service provider will receive your
            information on our behalf and will not be permitted to use it for
            any other purpose.
          </p>
          <p>
            Special category data is a subset of personal data and includes
            ethnicity, health, trade union membership, philosophical beliefs,
            sexual orientation, as well as other categories as prescribed by
            law. GT Cyber does not seek to obtain and will not collect such data
            about a candidate unless permitted to do so by applicable laws.
          </p>

          <h4>How We Use Your Personal Data </h4>
          <p>
            We collect and use your personal data for legitimate, human
            resources, business management or commercial reasons. This may
            include the following purposes:
          </p>
          <ul>
            <li>a) To respond to your requests,</li>
            <li>
              b) To provide you services, including customer services issues,
            </li>
            <li>
              c) To send you communications about current services of GT Cyber, new
              products and services or promotions that we are developing, and
              opportunities that may be available to you,
            </li>
            <li>
              d) Alerts for new features or enhancements to our products and
              services,
            </li>
            <li>
              e) To communicate about job or career opportunities which you have
              inquired,
            </li>
            <li>
              f) Identifying and evaluating candidates for potential employment,
              as well as for future roles that maybe become available,
            </li>
            <li>
              g) To ensure that our website and our services function in an
              effective manner,
            </li>
            <li>
              h) Marketing and events: We use personal data to deliver marketing
              and event communications to you across various platforms, such as
              email, telephone, text messaging, direct mail, online, etc. If we
              send you a marketing email, it will include instructions on how to
              opt out of receiving these emails in the future. Please remember
              that even if you opt out of receiving marketing emails, we may
              still send you important service information related to your
              accounts and subscriptions, and
            </li>
            <li>
              i) Legal obligations: We may be required to use and retain
              personal information for legal and compliance reasons, such as the
              prevention, detection, or investigation of a crime, loss
              prevention or fraud. We may also use personal data to meet our
              internal and external audit requirements, information security
              purposes, and as we otherwise believe to be necessary and
              appropriate.
            </li>
          </ul>
          <p>
            We endeavour to collect only such information that is reasonably
            necessary to perform services for you or to respond to your
            inquiries. You are responsible for ensuring that the Information you
            provide is accurate, complete and current. We will take reasonable
            steps ensure that the information we collect and use, is relevant to
            its intended use.
          </p>
          <h4>When We Share Personal Data</h4>
          <p>
            We share or disclose personal data when necessary to provide
            services or conduct our business operations.
          </p>
          <ul>
            <li>
              a) <span>Within GT Cyber: </span>Our businesses around the world are
              supported by GT Cyber and its group teams and functions, and personal
              data will be made available to them, if necessary, for the
              provision of services, account administration, sales and
              marketing, customer and technical support, business and product
              development for instance. All our employees and contractors are
              required to follow our Personal Data Protection Policy and
              Information Security policies when handling personal data.
            </li>
            <li>
              b)<span> Our business partners:</span> We occasionally collaborate
              with other organisations to deliver co-branded Services, to
              provide content, or to host events, conferences, and seminars. As
              part of these arrangements, you may be a customer of both GT Cyber
              and of our partners; we may collect and share information about
              you. We will handle personal data in accordance with the privacy
              policy.
            </li>
            <li>
              c)<span> Our third-party service providers:</span> We collaborate
              with and are supported by service providers around the world.
              Personal data will be made available to these parties only when
              necessary to fulfil the services they provide to us. For instance,
              software, system, and platform support; direct marketing services;
              cloud hosting services; advertising; and order fulfilment and
              delivery. Our thirdparty service providers are not permitted to
              share or use your personal data we make available to them, for any
              other purpose than to provide services to us. Third parties for
              legal reasons: We will share personal data when we believe it is
              required, such as:
              <ul>
                <li style={{ marginLeft: '30px' }}>
                  a. To comply with legal obligations and to respond to requests
                  from government agencies, including law enforcement and other
                  public authorities, which may include such authorities outside
                  your country of residence.
                </li>
                <li style={{ marginLeft: '30px' }}>
                  b. In the event of a merger, sale, restructure, acquisition,
                  joint venture, assignment, transfer, or other disposition of
                  all or any portion of our business, assets, or stock
                  (including in connection with any bankruptcy or similar
                  proceedings.
                </li>
              </ul>
            </li>
          </ul>
          <h4>Where We Store and Process Personal Data 
</h4>
<p>GT Cyber is a global organisation, and your personal data may be stored and processed globally. We take steps 
to ensure that the information we collect is processed according to this privacy policy and the requirements 
of applicable law wherever the data is located GT Cyber has networks, databases, servers, systems, support 
and helpdesks located throughout our offices around the world. We collaborate with third parties such as 
cloud hosting services, suppliers, and technology support located around the world to serve the needs of 
our business, workforce and customers. We take appropriate steps to ensure that personal data is 
processed, secured, and transferred according to applicable law thus protecting the confidentiality, 
availability, and integrity of your personal data.</p>
<h4>How We Secure Personal Data 






</h4>
<p>We take data security seriously, and we use appropriate technologies and procedures to protect personal 
data. Our information security policies and procedures are closely aligned with widely accepted 
international standards and are reviewed regularly and updated as necessary, to meet our business needs, 
changes in technology, and regulatory requirements</p>
<p>For instance:</p>
<ul>
  <li>a) Policies and procedures: GT Cyber employs reasonable technological, physical and operational 
security procedures intended to protect your personal data from loss, misuse, alteration, or 
unintentional destruction. Our security measures are periodically reviewed and updated with an 
effort to provide appropriate security for all your data collected through this website. </li>
<li>b) We place appropriate restrictions on access to personal data.</li>
<li>
c) We implement appropriate measures and controls, including monitoring and physical measures, 
to store and transfer data securely.</li>
<li>d) We conduct privacy, information security, and other applicable trainings on regular basis for our 
employees and contractors who have access to personal data and other sensitive data</li>
<li>e) We require our third-party vendors and providers to protect any personal data with which they are 
entrusted, in accordance with our information security policies and procedures.</li>
</ul>
<h4>How Long We Keep Your Personal Data </h4>
<p>We retain the personal data for so long as is reasonably required for legal or business purposes. In 
determining data retention periods, GT Cyber takes into consideration local laws, contractual obligations, and 
the expectations and requirements of our customers. When we no longer need personal information, we 
securely delete or destroy it.</p>
<h4>Your choice to unsubscribe or opt-out </h4>
<p>
If you wish to unsubscribe from this website at any time, you can send us an email at <a href='mailto:contact@gtcyber.com'>contact@gtcyber.com</a>. We will retain your information for as long as our legal or business requirements dictate.
</p>
<h4>Your rights </h4>
<p>We take reasonable steps that are designed to keep your personal data accurate, complete, and up to date
for the purposes for which it is collected and used. GT Cyber has also implemented measures that are designed 
to ensure that processing of your personal data complies with this privacy policy and applicable law(s)</p>
<ul>
  <li>• You may have the right to request access to your personal data that GT Cyber has collected, for the 
purposes of reviewing, modifying, or requesting deletion of your data. </li>
<li>• You may have the right to request a copy of your personal data GT Cyber has collected and to have 
any inaccuracies corrected.</li>
<li>
• You may also request GT Cyber to cease the processing of your personal data (e.g., if you choose to 
withdraw from our recruiting or for products and services program), although note that we may 
need to retain certain personal data where required or permitted under applicable law. </li>
<li>• You may also have a right to object processing of your personal data that GT Cyber has collected.</li>

</ul>

<p>If you would like to make a request to access, review or correct your personal data that GT Cyber has collected, 
or to discuss how we process your personal data, please contact us at <a href='mailto:contact@gtcyber.com'>contact@gtcyber.com</a>
</p>
<p>*The above rights are as per the GDPR requirements and can be exercised by EU data subjects, only.</p>
<p>*Additionally, under provisions of Indian IT Act 2000, G.S.R. 313(E), Rules for Reasonable security practices and procedures for sensitive personal data or information, providers of sensitive personal information 
within India can exercise these rights for the sensitive personal information provided by them to GT Cyber. 
These providers of the sensitive personal information can also report the discrepancies or grievances with 
respect to processing of their information at the above-mentioned email address i.e. <a href='mailto:contact@gtcyber.com'>contact@gtcyber.com</a> which represents the contact detail of Data Protection Representative or the Grievance Officer 
at GT Cyber for redressing such grievances</p>
<p>To help protect privacy and security, we will take reasonable steps to verify your identity before granting 
you access to your personal data. We will make reasonable attempts to promptly investigate, comply with, 
or otherwise respond to your requests as may be required by applicable law. Depending upon the request, 
we may not be permitted to provide access to personal data or otherwise fully comply with your request; 
for example, where producing your information may reveal the identity of someone else. GT Cyber reserves 
the right to charge an appropriate fee for complying with your request were allowed by applicable law, 
and/or to deny your requests which in GT Cyber's discretion, may be unfounded, excessive, or otherwise 
unacceptable under applicable law.</p>

<h4>Cookies </h4>
<p>From time to time, we may, like many other commercial websites, use a standard technology called a 
"cookie". A cookie is a small text file that is placed on a computer or other device and is used to identify the 
user or device and to collect information. Cookies are typically assigned to one of four categories, 
depending on their function and intended purpose: necessary cookies, performance cookies, functional 
cookies, and cookies for marketing purposes. A cookie cannot retrieve any other data from your hard drive, 
pass on computer viruses, or capture your e-mail address. Currently, websites use cookies to enhance the 
user's visit; in general, cookies can securely store a user's ID and password, personalize home pages, and 
identify which parts of a website have been visited. It is possible to set your browser to inform you when a 
cookie is being placed. This way you have the opportunity to decide whether to accept the cookie. By 
showing how and when our visitors use this website, this information can help us to improve our website 
on a continual basis.</p>
<h4>Children </h4>
<p>This Website is not intended to collect or maintain personal data of individuals under 13 (thirteen) years of 
age. IF YOU ARE UNDER 13 YEARS OF AGE, PLEASE DO NOT USE OR ACCESS THIS WEBSITE. If GT Cyber learns 
that personal data of an individual under 13 years old has been collected without verifiable parental 
consent, then GT Cyber will take the appropriate steps to delete such information.</p>
<h4>Contact </h4>
<p>If you have any questions about this privacy policy, please contact us at <a href='mailto:contact@gtcyber.com'>contact@gtcyber.com </a>
GT Cyber reserves the right to modify this privacy policy. GT Cyber will post changes to the privacy policy on this 
page. Please check this page at regular intervals for updates</p>


        </div>
      </div>
        </Container>
      </Grid>
     
    </div>
  )
}

export default PrivacyPolicy
