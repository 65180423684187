import { Container, Grid, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import './opinion.css'
import 'aos/dist/aos.css';
import Aos from 'aos';
export default function Opinion() {
    useEffect(() => {
        Aos.init()
       }, [])
  return (
    
    <Container>
<Grid container>









    <Grid item sm={12} xs={12}>
        <Typography data-aos='fade-right' className='mainHead_opn'>
          Our Values  
        </Typography>
    </Grid>
    <Grid item sm={12} xs={12}>
        <Typography data-aos='fade-right' className='maindesc_opn'>
        At GT cyber Labs , we are transforming cybersecurity by committing ourselves to a future where our protective measures are not only more reliable and secure but also inherently agile and process-oriented. This transformation is driven by an understanding that the dynamic nature of cyber threats requires a framework that is both robust and flexible, allowing for swift adaptation to the ever-evolving digital threatscape.

        </Typography>
    </Grid>
    <Grid item sm={12} xs={12}>
        <Typography data-aos='fade-right' className='subdesc_opn'>
        <b style={{color:'var(--color1)'}}>Reliability</b>: We are enhancing the dependability of our cybersecurity solutions, ensuring they consistently perform to the highest standards, providing continuous protection against known and emerging threats.
 
        </Typography>
    </Grid>
    <Grid item sm={12} xs={12}>
        <Typography data-aos='fade-right' className='subdesc_opn'>
        <b style={{color:'var(--color1)'}}>Agility</b>: Our proactive stance embraces agility, enabling us to rapidly pivot and adjust our strategies and solutions in real-time as new threats and vulnerabilities are identified, ensuring that security is maintained without interruption.
 
        </Typography>
    </Grid>
    <Grid item sm={12} xs={12}>
        <Typography data-aos='fade-right' className='subdesc_opn'>
        <b style={{color:'var(--color1)'}}>Security</b>: : At the core of our transformation is the unwavering commitment to fortify all layers of digital infrastructure against unauthorized access, breaches, and data theft, harnessing advanced technologies and methodologies to stay ahead of cybercriminals.
 
        </Typography>
    </Grid>
    <Grid item sm={12} xs={12}>
        <Typography data-aos='fade-right' className='subdesc_opn'>
        <b style={{color:'var(--color1)'}}>Process-Oriented</b>: : We are streamlining our operations to be more process-oriented, fostering a culture where every action and decision is guided by well-defined procedures that not only comply with the highest industry standards but also enhance our operational efficiency and response capabilities.
 
        </Typography>
    </Grid>
    <Grid item sm={12} xs={12}>
        <Typography data-aos='fade-right' className='subdesc_opn'>
        Through this transformation, GT Cyber is setting a new benchmark for cybersecurity excellence—delivering peace of mind in the digital age by protecting assets, safeguarding transactions, and ensuring the privacy and integrity of data for all our clients and partners.
 
        </Typography>
    </Grid>
</Grid>
</Container>

  )
}
