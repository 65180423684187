import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore' ;

import Accordion from '@mui/material/Accordion';
import{ AccordionSummary,AccordionDetails, Grid} from '@mui/material';
import Typography from '@mui/material/Typography';
import ArrowForwardIcon from '@mui/icons-material/ArrowBack';
import './D6_Sl_Drawer.css'
import { ArrowCircleRight } from '@mui/icons-material';
export default function TemporaryDrawer(props) {

  const [open,setOpen]=React.useState(true)


  

  const Open=()=>{
    setOpen(!open)
  }
const Toggle=()=>{
  props.Toggle()
}
  const list = (anchor) => (
    <Box
      className='box_W'
      role="presentation"
      onClick={Open}
      onKeyDown={Open} 
      
    >
      <List>
        <ListItem onClick={()=>{Toggle()}}>
        <ListItemIcon style={{cursor:'pointer',paddingBottom:'2px',color:'var(--color1)'}}>
      <ArrowCircleRight style={{height:35,width:35}} />
      </ListItemIcon>
      </ListItem>


    


        {(['Home']).map((text, index) => (
          <Accordion  onClick={()=>window.location.pathname=`${text=='Home'?'/':text=='About us'?'/aboutus':text=='Contact us'?'/contactus':text=='Company Compliance'?'/companycompliance':text=='Our services'?'ourservices':'/'}`} button key={text}>
           <AccordionSummary aria-controls="panel1a-content"
          id="panel1a-header">
            <Typography className='typo' >{text}</Typography> 
            </AccordionSummary>
          </Accordion>
        ))}
    <Accordion>
        <AccordionSummary
                style={{margin:'5px 0px'}}

          expandIcon={<ExpandMoreIcon style={{color:'var(--color1)'}}  />}
          aria-controls="panel1a-content"
          id="panel1a-header" 
        >
          <Typography className='typo'  >Product</Typography>
        </AccordionSummary>

        <AccordionDetails style={{display:'grid'}}>


     
        <Grid container alignContent={'center'} sm={12} xs={12}>
    <Grid item sm={12} xs={12}>
      <Typography className='nav_cmp_main_head'>Platform Highlights</Typography>
    </Grid>
    </Grid>
    <Grid item  sm={12} style={{padding:'20px 2vw'}} xs={12}>
    

    <Grid  onClick={()=>window.location.href='/network-security'} container sm={12} xs={12}>
    <Grid item sm={12} xs={12}>
      <Typography className='nav_cmp_sub_head hvr-underline-from-left-purple'>Network Security</Typography>
    </Grid>
    <Grid item sm={12} xs={12}>
      <Typography color={'text.secondary'} >Delivering vulnerability management, attack surface management, and stronger security posture through the digital twin model</Typography>
    </Grid>
    </Grid>

    <Grid container  onClick={()=>window.location.href='/endpoint-security'} sm={12} xs={12}>
    <Grid item sm={12} xs={12}>
      <Typography className='nav_cmp_sub_head hvr-underline-from-left-purple'>Endpoint Security</Typography>
    </Grid>
    <Grid item sm={12} xs={12}>
      <Typography color={'text.secondary'} >Gain end-to-end visibility, service assurance and continuously audit your entire cloud estate with GT Cyber and Forward Cloud</Typography>
    </Grid>
    </Grid>
   
    <Grid container onClick={()=>window.location.href='/identity-security'}  sm={12} xs={12}>
    <Grid item sm={12} xs={12}>
      <Typography className='nav_cmp_sub_head hvr-underline-from-left-purple'>Identity Security</Typography>
    </Grid>
    <Grid item sm={12} xs={12}>
      <Typography color={'text.secondary'} >
True network reliability, agility, and security by collecting and organizing network data to make it useful and actionable</Typography>
    </Grid>
    </Grid>
    <Grid container  
    // onClick={()=>window.location.href='/cloud-security'} 
    sm={12} xs={12}>
    <Grid item sm={12} xs={12}>
      <Typography className='nav_cmp_sub_head hvr-underline-from-left-purple'>Cloud Security</Typography>
    </Grid>
    <Grid item sm={12} xs={12}>
      <Typography color={'text.secondary'} >Gain end-to-end visibility, service assurance and continuously audit your entire cloud estate with GT Cyber and Forward Cloud</Typography>
    </Grid>
    </Grid>
    <Grid  
    // onClick={()=>window.location.href='/digital-risk-compliance'}
     container sm={12} xs={12}>
    <Grid item sm={12} xs={12}>
      <Typography className='nav_cmp_sub_head hvr-underline-from-left-purple'>Digital Risk & Compliance</Typography>
    </Grid>
    <Grid item sm={12} xs={12}>
      <Typography color={'text.secondary'} >Delivering vulnerability management, attack surface management, and stronger security posture through the digital twin model</Typography>
    </Grid>
    </Grid>
  </Grid>
        
        
         
       
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
                style={{margin:'5px 0px'}}

          expandIcon={<ExpandMoreIcon style={{color:'var(--color1)'}}  />}
          aria-controls="panel1a-content"
          id="panel1a-header" 
        >
          <Typography className='typo'  >Solutions</Typography>
        </AccordionSummary>

        <AccordionDetails style={{display:'grid'}}>

     
        <Grid container  alignContent={'center'}  style={{padding:'20px 2vw'}} xs={12} sm={12} >
    
    <Grid item sm={12} xs={12}>
      <Typography className='nav_cmp_main_head'>SOLUTIONS</Typography>
    </Grid>
    <Grid container sm={12} xs={12}>
   
    <Grid item sm={12} xs={12}>
      <Typography className='nav_cmp_sub_head'>Overview</Typography>
    </Grid>
    <Grid item sm={12} xs={12}>
      <Typography color={'text.secondary'} >GT Cyber can help solve a spectrum of network challenges. Explore by use case, technology or industry.
</Typography>
    </Grid>
    </Grid>
    <Grid container sm={12} xs={12}>
    <Grid item sm={12} xs={12}>
      <Typography className='nav_cmp_sub_head'>Federal Solutions</Typography>
    </Grid>
    <Grid item sm={12} xs={12}>
      <Typography color={'text.secondary'} >GT Cyber provides unified visibility across agency estates, ensures network resiliency, and expedites remediation
</Typography>
    </Grid>
    </Grid>
    <Grid container sm={12} xs={12}>
    <Grid item sm={12} xs={12}>
      <Typography className='nav_cmp_sub_head'>Integrations</Typography>
    </Grid>
    <Grid item sm={12} xs={12}>
      <Typography color={'text.secondary'} >API Integrations that enhance efficiency through seamless data exchange with the GT Cyber platform</Typography>
    </Grid>
    </Grid>
    </Grid>
    <Grid container   style={{padding:'20px 2vw'}}  sm={12} xs={12}>
    
    <Grid item sm={12} xs={12}>
      <Typography className='nav_cmp_main_head'>SOLUTION EXAMPLES</Typography>
    </Grid>
    <Grid container sm={12} xs={12}>
   
    <Grid item sm={12} xs={12}>
      <Typography className='nav_cmp_sub_head'>Universal IT</Typography>
    </Grid>
    <Grid item sm={12} xs={12}>
      <Typography color={'text.secondary'} >Explore ways GT Cyber can improve Inventory management, change control, compliance + auditing, and workflow

</Typography>
    </Grid>
    </Grid>
    <Grid container sm={12} xs={12}>
    <Grid item sm={12} xs={12}>
      <Typography className='nav_cmp_sub_head'>Network Security</Typography>
    </Grid>
    <Grid item sm={12} xs={12}>
      <Typography color={'text.secondary'} >Delivering vulnerability management, attack surface management, and stronger security posture through the digital twin model

</Typography>
    </Grid>
    </Grid>
    <Grid container sm={12} xs={12}>
    <Grid item sm={12} xs={12}>
      <Typography className='nav_cmp_sub_head'>Multi-Cloud</Typography>
    </Grid>
    <Grid item sm={12} xs={12}>
      <Typography color={'text.secondary'} >Gain end-to-end visibility, service assurance and continuously audit your entire cloud estate with GT Cyber and Forward Cloud
</Typography>
    </Grid>
    </Grid>
    </Grid>
         
       
        </AccordionDetails>
      </Accordion>
      {(['About us','Contact us' ]).map((text, index) => (
          <Accordion  onClick={()=>window.location.pathname=`${text=='Home'?'/':text=='About us'?'/about-us':text=='Contact us'?'/contact-us':'/'}`} button key={text}>
           <AccordionSummary aria-controls="panel1a-content"
          id="panel1a-header">
            <Typography className='typo' >{text}</Typography> 
            </AccordionSummary>
          </Accordion>
        ))}
     
      {/* {['Gallery', 'Blogs','Contact us'].map((text, index) => ( */}
    
      </List>

    </Box>
  );

  return (
    // <div>
    <>
      {['right'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Drawer
            anchor={'right'}
            open={props.open}
            onClose={Open}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
      </>
    // </div>
  );
}