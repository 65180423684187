import { Container, Grid, Paper, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import './home_top.css'
import './home_top.scss'
import 'aos/dist/aos.css';
import Aos from 'aos';
export default function Home_top() {
  useEffect(() => {
   Aos.init()
  }, [])
  
  
  const data=['Vulnerability management','Security posture | Zero trust','Attack surface management | Incident Response | Threat Hunting','End-to-end visibility','Service assurance,Outage prevention','Continuous security audit','Configuration Assessment','Compliance & Audit','Training Services','Deployment','Active Directory Assessment','Inventory management',]
  return (


<Grid container>

<Grid container style={{marginTop:0}} className='home_top_main_comp'>
  <Grid container style={{}} className='home_top_bg' >
<Container  >
<Grid item sm={12} xs={12}>
    <Typography   data-aos='fade-right'  style={{color:'var(--color2)'}} className='home_top_txt1'>
    BEST-IN-CLASS Cyber Security People, Process & Technology
    </Typography>
</Grid>
<Grid item sm={12} xs={12}>
    <Typography data-aos='fade-right'     style={{color:'white'}} className='home_top_txt2_new'>
    How harmonious integration of People, process & Technology solve NextGen Cybersecurity challenges?  </Typography>
</Grid>

<Grid item sm={12} xs={12}>
    <Typography data-aos='fade-right'  style={{color:'white'}} className='home_top_txt3'>
    From [A]utomation to[ Z]ero Trust, GT Cyber Labs economize time, finances, and avoid hassle with the unique approach

</Typography>
</Grid>
</Container>
</Grid>
<Container className='home_top_bg2' >
<Grid container sm={12} xs={12}>
 <Grid item sm={12} xs={12}>
 <Typography data-aos='fade-up'   className='home_top_txt_scroll_main'>
 By Leveraging GT Cyber Labs solutions..
    </Typography>
    </Grid>
{data.map((val,idx)=>
  <Grid data-aos='zoom-in-up'  key={idx} item style={{padding:15}} sm={3} xs={6}>
  <Paper className='home_top_ppr' >
    <Typography  className='home_top_ppr_txt'>
{val}
</Typography>
  </Paper>
</Grid>)}

</Grid>

<Grid container>
  <Grid item sm={12} xs={12}>
    <Typography data-aos='fade-right' className='home_top_use_headM' >Explore what GT Cyber Labs can do to enhance your security posture
</Typography>
  </Grid>
  <Grid item sm={12} xs={12}>
    <Typography data-aos='fade-right'  className='home_top_txt3' style={{padding:'0vh 1vw'}} >With one single platform, users can improve network security, query the network like a database, and finally gain visibility across instances
</Typography>
  </Grid>

  <Grid container textAlign={'-webkit-center'} style={{padding:'5vh 1vw'}}>
    <Grid data-aos='zoom-in-up'  style={{padding:'0vh 20px',cursor:'pointer'}} item sm={4} xs={12}>
    <Paper onClick={()=>window.location.href='/network-data-intelligence'} className='home_top_ppr2' >
    <Typography  className='home_top_ppr2_head'>
    Network Operations
</Typography>
<Typography  className='home_top_ppr2_desc'>
Save time with instant searchable access to all global network information, and proactively verify that network behavior matches your intent
</Typography>
  </Paper>
    </Grid>
    <Grid data-aos='zoom-in-up'  style={{padding:'0vh 20px',cursor:'pointer'}} item sm={4} xs={12}>
    <Paper onClick={()=>window.location.href='/network-security'} className='home_top_ppr2' >
    <Typography  className='home_top_ppr2_head'>
    Network security
</Typography>
<Typography  className='home_top_ppr2_desc'>
Improve SOC efficiency through attack surface, vulnerability, and security posture management
</Typography>
  </Paper>
    </Grid>
    <Grid  data-aos='zoom-in-up' style={{padding:'0vh 10px',cursor:'pointer'}} item sm={4} xs={12}>
    <Paper className='home_top_ppr2' onClick={()=>window.location.href='/multi-cloud'} >
    <Typography  className='home_top_ppr2_head'>
    Multi-cloud
</Typography>
<Typography  className='home_top_ppr2_desc'>
Get end-to-end visibility for multi-cloud environments and validate security controls, in a single pane of glass
</Typography>
  </Paper>
    </Grid>
  </Grid>
</Grid>

<Grid container>
  <Grid data-aos='fade-right'  item sm={12} xs={12}>
    <Typography className='home_top_use_headM' >Customers are unanimous: GT Cyber is a game-changer
</Typography>
  </Grid>
  {/* <Grid data-aos='fade-right'  item sm={12} xs={12}>
    <Typography className='home_top_txt3' style={{padding:'0vh 1vw'}} >From Fortune 50 institutions to top level federal agencies, users agree that GT Cyber is unlike any other network modeling software
</Typography>
  </Grid> */}

  <Grid container textAlign={'-webkit-center'} style={{padding:'5vh 1vw'}}>
    <Grid data-aos='zoom-in-up'  style={{padding:'0vh 20px'}} item sm={4} xs={12}>
    <Paper  className='home_top_ppr3' >
    <Typography   className='home_top_ppr3_head'>
Explore the features

</Typography>

<img src={require('../../../images/explore.gif')}  className='home_top_ppr_gif'  />
<Typography  className='home_top_ppr3_desc'>
Query your entire network like a database, set up non-compliance detection alerts, merge network snapshots, view every possible network path</Typography>
  </Paper>
    </Grid>
    <Grid data-aos='zoom-in-up'  style={{padding:'0vh 20px'}} item sm={4} xs={12}>
    <Paper className='home_top_ppr3' >
    <Typography  className='home_top_ppr3_head'>
    Evaluate the reviews
</Typography>
<img src={require('../../../images/review.gif')}  className='home_top_ppr_gif'  />

<Typography  className='home_top_ppr3_desc'>
Read the reviews from G2 and Gartner Peer Reviews that explain why "GT Cyber is unlike any other tool out there"
</Typography>
  </Paper>
    </Grid>
    <Grid  data-aos='zoom-in-up'  style={{padding:'0vh 10px'}} item sm={4} xs={12}>
    <Paper className='home_top_ppr3' >
    <Typography  className='home_top_ppr3_head'>
    Try it out
</Typography>
<img src={require('../../../images/try.gif')}  className='home_top_ppr_gif'  />

<Typography  className='home_top_ppr3_desc'>
Test drive GT Cyber on your own by playing Forward Quest. Or request a demo by a GT Cyber exper
</Typography>
  </Paper>
    </Grid>
  </Grid>
</Grid>


</Container>
</Grid>



</Grid>

  )
}
