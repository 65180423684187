import React, { useState } from 'react';
import {NavLink} from "react-router-dom";

import Grid from '@mui/material/Grid';
import './header.css'
import { Button, Typography } from '@mui/material';
import TemporaryDrawer from './D6_Sl_Drawer';

function Navbar() {
  const [navbarA,setNavbar] = useState(false);
  const changeBackground = () => {
    if(window.scrollY >= 100)
    {
        setNavbar(true);
    }
    else {
        setNavbar(false);
    }
};
const [open, setOpen] = useState(false)
 
const Toggle=()=>{
  setOpen(!open)
}
window.addEventListener('scroll',changeBackground);
      


return(
            <nav>
            <div style={{width:'100%',justifyItems:'center',display:'grid'}}>
            <div className={'Navbar_Items'}>

<Grid justifyContent={'flex-end'} display={'flex'} alignItems={'center'} container   className='gird_nav'>

<Grid container sm={3.5} xs={10}>
{/* <div style={{height:50,width:50,borderRadius:'50%',backgroundColor:'white',marginLeft:'2vw'}}> */}
<img  src={require('../../../images/logo_nav.png')} onClick={()=>window.location.href='/'}  style={{height:90,cursor:'pointer'}} ></img>

{/* </div> */}
</Grid>
<Grid container  className='header_mob' justifyContent={'flex-start'} sm={6.5} xs={12}>
<Grid item >
<NavLink className={({ isActive }) => isActive ? "Navbar_Link2" : "Navbar_Link1"}  to="/"   >
                              HOME




                            </NavLink>



</Grid>
<Grid item className='product'>
<NavLink className={({ isActive }) => isActive ? "Navbar_Link1" : "Navbar_Link1"}   >
                              PRODUCT

<div className='nav_cmp_main_p'  >
<div className='nav_sub_cmp_p'>
<Grid container>
  <Grid container textAlign={'left'} sm={8} style={{padding:'20px 4vw',backgroundColor:'white'}} xs={6}>


    <Grid container alignContent={'center'} sm={6} xs={6}>
    <Grid item sm={12} xs={12}>
      <Typography className='nav_cmp_main_head'>Platform Highlights</Typography>
    </Grid>
    </Grid>
    <Grid item  sm={6} style={{padding:'20px 2vw'}} xs={6}>
    <Grid  onClick={()=>window.location.href='/network-security'} container sm={12} xs={12}>
    <Grid item sm={12} xs={12}>
      <Typography className='nav_cmp_sub_head hvr-underline-from-left-purple'>Network Security</Typography>
    </Grid>
    <Grid item sm={12} xs={12}>
      <Typography color={'text.secondary'} >Delivering vulnerability management, attack surface management, and stronger security posture through the digital twin model</Typography>
    </Grid>
    </Grid>

    <Grid container  onClick={()=>window.location.href='/endpoint-security'} sm={12} xs={12}>
    <Grid item sm={12} xs={12}>
      <Typography className='nav_cmp_sub_head hvr-underline-from-left-purple'>Endpoint Security</Typography>
    </Grid>
    <Grid item sm={12} xs={12}>
      <Typography color={'text.secondary'} >Gain end-to-end visibility, service assurance and continuously audit your entire cloud estate with GT Cyber and Forward Cloud</Typography>
    </Grid>
    </Grid>
   
    <Grid container onClick={()=>window.location.href='/identity-security'}  sm={12} xs={12}>
    <Grid item sm={12} xs={12}>
      <Typography className='nav_cmp_sub_head hvr-underline-from-left-purple'>Identity Security</Typography>
    </Grid>
    <Grid item sm={12} xs={12}>
      <Typography color={'text.secondary'} >
True network reliability, agility, and security by collecting and organizing network data to make it useful and actionable</Typography>
    </Grid>
    </Grid>
  </Grid>
  </Grid>
  <Grid item textAlign={'left'} alignContent={'center'} sm={4} style={{padding:'20px 4vw'}} xs={6}>
  <Grid item sm={12} xs={12}>
      <Typography className='nav_cmp_main_head'>More from GT Cyber</Typography>
    </Grid>

    <Grid container 
    //  onClick={()=>window.location.href='/cloud-security'} 
     sm={12} xs={12}>
    <Grid item sm={12} xs={12}>
      <Typography className='nav_cmp_sub_head hvr-underline-from-left-purple'>Cloud Security</Typography>
    </Grid>
    <Grid item sm={12} xs={12}>
      <Typography color={'text.secondary'} >Gain end-to-end visibility, service assurance and continuously audit your entire cloud estate with GT Cyber and Forward Cloud</Typography>
    </Grid>
    </Grid>
    <Grid  
    // onClick={()=>window.location.href='/digital-risk-compliance'} 
    container sm={12} xs={12}>
    <Grid item sm={12} xs={12}>
      <Typography className='nav_cmp_sub_head hvr-underline-from-left-purple'>Digital Risk & Compliance</Typography>
    </Grid>
    <Grid item sm={12} xs={12}>
      <Typography color={'text.secondary'} >Delivering vulnerability management, attack surface management, and stronger security posture through the digital twin model</Typography>
    </Grid>
    </Grid>
  </Grid>
</Grid>
</div>
</div>


                            </NavLink>



</Grid>





<Grid item className='solutions'>
<NavLink className={ "Navbar_Link1"}     >
                              SOLUTIONS

<div className='nav_cmp_main_s'  >
<div className='nav_sub_cmp_s'>
<Grid container>
  <Grid container textAlign={'left'} sm={8} style={{padding:'20px 4vw',backgroundColor:'white'}} xs={6}>


    <Grid container style={{paddingRight:20}} sm={6} xs={6}>
    
    <Grid item sm={12} xs={12}>
      <Typography className='nav_cmp_main_head'>SOLUTIONS</Typography>
    </Grid>
    <Grid container sm={12} xs={12}>
   
    <Grid item sm={12} xs={12}>
      <Typography className='nav_cmp_sub_head hvr-underline-from-left-purple'>Vulnerability Management</Typography>
    </Grid>
    <Grid item sm={12} xs={12}>
      <Typography color={'text.secondary'} >GT Cyber Vulnerability Management, a comprehensive solution designed to safeguard your digital assets against emerging threats & vulnerabilities.
</Typography>
    </Grid>
    </Grid>
    <Grid container sm={12} xs={12}>
    <Grid item sm={12} xs={12}>
      <Typography className='nav_cmp_sub_head hvr-underline-from-left-purple'>Security Posture Check</Typography>
    </Grid>
    <Grid item sm={12} xs={12}>
      <Typography color={'text.secondary'} >Enhancing Resilience: Safeguarding Your Digital Fort with GT CyberSecurity Posture Check!
</Typography>
    </Grid>
    </Grid>
    <Grid container sm={12} xs={12}>
    <Grid item sm={12} xs={12}>
      <Typography className='nav_cmp_sub_head hvr-underline-from-left-purple'>Attack surface management</Typography>
    </Grid>
    <Grid item sm={12} xs={12}>
      <Typography color={'text.secondary'} >API Integrations that enhance efficiency through seamless data exchange with the GT CStrengthening Defenses: Securing Your Digital Perimeter with GT Cyber Attack Surface Management!</Typography>
    </Grid>
    </Grid>
    <Grid container sm={12} xs={12}>
    <Grid item sm={12} xs={12}>
      <Typography className='nav_cmp_sub_head hvr-underline-from-left-purple'>End-to-End Visibility</Typography>
    </Grid>
    <Grid item sm={12} xs={12}>
      <Typography color={'text.secondary'} >Unlocking Insights: Transforming Security with GT Cyber End-to-End Visibility!</Typography>
    </Grid>
    </Grid>
    <Grid container sm={12} xs={12}>
    <Grid item sm={12} xs={12}>
      <Typography className='nav_cmp_sub_head hvr-underline-from-left-purple'>Zero Trust </Typography>
    </Grid>
    <Grid item sm={12} xs={12}>
      <Typography color={'text.secondary'} >Reimagining Security: Embrace Trust in a Zero Trust World with GT Cyber Zero Trust!</Typography>
    </Grid>
    </Grid>
    </Grid>
    <Grid container  sm={6} xs={6}>
    
    <Grid item sm={12} xs={12}>
      <Typography className='nav_cmp_main_head'>SOLUTION EXAMPLES</Typography>
    </Grid>
    <Grid container sm={12} xs={12}>
   
    <Grid item sm={12} xs={12}>
      <Typography className='nav_cmp_sub_head hvr-underline-from-left-purple'>Service assurance, Outage Prevention </Typography>
    </Grid>
    <Grid item sm={12} xs={12}>
      <Typography color={'text.secondary'} >At GT Join us on this transformative journey as we revolutionize service assurance and outage prevention. Together, let's build a resilient and reliable digital future

</Typography>
    </Grid>
    </Grid>
    <Grid container sm={12} xs={12}>
    <Grid item sm={12} xs={12}>
      <Typography className='nav_cmp_sub_head hvr-underline-from-left-purple'>Continuous Security Service </Typography>
    </Grid>
    <Grid item sm={12} xs={12}>
      <Typography color={'text.secondary'} >Uninterrupted Protection: Embracing Security Excellence with GT Cyber Continuous Security Service!

</Typography>
    </Grid>
    </Grid>
    <Grid container sm={12} xs={12}>
    <Grid item sm={12} xs={12}>
      <Typography className='nav_cmp_sub_head hvr-underline-from-left-purple'>Configuration Assessment</Typography>
    </Grid>
    <Grid item sm={12} xs={12}>
      <Typography color={'text.secondary'} > Optimizing Security: Elevating Your Defenses with GT Cyber Configuration Assessment!
</Typography>
    </Grid>
    </Grid>
    <Grid container sm={12} xs={12}>
    <Grid item sm={12} xs={12}>
      <Typography className='nav_cmp_sub_head hvr-underline-from-left-purple'>Compliance & Audit</Typography>
    </Grid>
    <Grid item sm={12} xs={12}>
      <Typography color={'text.secondary'} > Navigating Regulatory Waters: Ensuring Compliance and Audit Readiness with GT Cyber Compliance & Audit!
</Typography>
    </Grid>
    </Grid>
    <Grid container sm={12} xs={12}>
    <Grid item sm={12} xs={12}>
      <Typography className='nav_cmp_sub_head hvr-underline-from-left-purple'>Threat Hunting</Typography>
    </Grid>
    <Grid item sm={12} xs={12}>
      <Typography color={'text.secondary'} > Proactive Defense: Stay Ahead of Threats with GT Cyber Threat Hunting!
</Typography>
    </Grid>
    </Grid>
    </Grid>
  </Grid>
  <Grid item  sm={4} style={{padding:'20px 4vw',textAlign:'left',alignContent:'center'}} xs={6}>
  {/* <Grid item sm={12} xs={12}>
      <Typography className='nav_cmp_main_head'>SOLUTION EXAMPLES</Typography>
    </Grid> */}
    <Grid container sm={12} xs={12}>
   
    <Grid item sm={12} xs={12}>
      <Typography className='nav_cmp_sub_head hvr-underline-from-left-purple'>Training Service </Typography>
    </Grid>
    <Grid item sm={12} xs={12}>
      <Typography color={'text.secondary'} > Empowering Excellence: Elevating Cyber Proficiency with GT Cyber Training Services!

</Typography>
    </Grid>
    </Grid>
    <Grid container sm={12} xs={12}>
    <Grid item sm={12} xs={12}>
      <Typography className='nav_cmp_sub_head hvr-underline-from-left-purple'>Deployment Service </Typography>
    </Grid>
    <Grid item sm={12} xs={12}>
      <Typography color={'text.secondary'} >Empowering Your Cyber Defense: Unleashing the Potential of GT Cyber Deployment Service!

</Typography>
    </Grid>
    </Grid>
    <Grid container sm={12} xs={12}>
    <Grid item sm={12} xs={12}>
      <Typography className='nav_cmp_sub_head hvr-underline-from-left-purple'>Active Directory Assessment</Typography>
    </Grid>
    <Grid item sm={12} xs={12}>
      <Typography color={'text.secondary'} >Strengthening Your Foundation: Optimizing Security with GT Cyber Active Directory Assessment!
</Typography>
    </Grid>
    </Grid>
    <Grid container sm={12} xs={12}>
    <Grid item sm={12} xs={12}>
      <Typography className='nav_cmp_sub_head hvr-underline-from-left-purple'>Inventory Management</Typography>
    </Grid>
    <Grid item sm={12} xs={12}>
      <Typography color={'text.secondary'} >Unlocking Efficiency: Streamlining Security with GT Cyber Inventory Management!
</Typography>
    </Grid>
    </Grid>
    <Grid container sm={12} xs={12}>
    <Grid item sm={12} xs={12}>
      <Typography className='nav_cmp_sub_head hvr-underline-from-left-purple'> Incident Response</Typography>
    </Grid>
    <Grid item sm={12} xs={12}>
      <Typography color={'text.secondary'} >Rapid Resilience: Responding to Cyber Threats with GT Cyber Incident Response!
</Typography>
    </Grid>
    </Grid>

 
  </Grid>
</Grid>
</div>
</div>


                            </NavLink>



</Grid>

                            <Grid item>
<NavLink className={({ isActive }) => isActive ? "Navbar_Link2" : "Navbar_Link1"}  to="/about-us"   >
ABOUT US
                            </NavLink>
</Grid>
<Grid item>
<NavLink className={({ isActive }) => isActive ? "Navbar_Link2" : "Navbar_Link1"}  to="/contact-us"   >
CONTACT US
                            </NavLink>
                           
</Grid>







</Grid>

<Grid container className='header_mob'  justifyContent={'flex-end'} sm={2} xs={2}>
<Button onClick={()=>window.location.href='/contact-us'}   className='nav_btn  header_mob' >Book a demo</Button>

</Grid>
<Grid container className='header_mob2' justifyContent={'flex-end'} sm={2} xs={2}>

<i onClick={()=>setOpen(true)} style={{fontSize:'2rem'}} class="fas fa-bars"></i>
<TemporaryDrawer Toggle={Toggle} open={open}  />

</Grid>

    </Grid>                  
            </div>


            </div>





            </nav>
        );
    }
    
    export default Navbar;