import { Button, Container, Grid, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import 'aos/dist/aos.css';
import Aos from 'aos';
export default function Expert() {
  
  useEffect(() => {
    Aos.init()
   }, [])
   
  return (
    <Grid  style={{background:'#eee'}} container>
    <Container style={{padding:'10vh 0px'}}>
    
    <Grid data-aos="flip-up" container>
    
        <Grid item sm={12} xs={12}>
        <Typography style={{color:'var(--color1)',textAlign:'center'}} className='home_below_mis_sub_head'>
        Ready to get started?
    </Typography>  
        </Grid>
    
        <Grid container style={{marginTop:'2vh'}} sm={12} xs={12}>
        <Grid item style={{paddingRight:'2vw'}} textAlign={'end'}  sm={6} xs={6}>
        <Button onClick={()=>window.location.href='/contact-us'}  className='home_below_ready_btn1'>
       Request a demo
    </Button>  
        </Grid>
        <Grid item textAlign={'left'} sm={6} xs={6}>
        <Button onClick={()=>window.location.href='/contact-us'}  className='home_below_ready_btn2'>
        Chat with expert
    </Button>  
        </Grid>
        </Grid>
    </Grid>
    
    </Container>
    </Grid>
  )
}
