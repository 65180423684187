import { Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import './contact.css'
import AOS from 'aos';
import 'aos/dist/aos.css';
export default function Contact() {
  
  
  
    const [email, setEmail] = useState('')
    const [fname, setFName] = useState('')
    const [lname, setLName] = useState('')

    const [phone, setPhone] = useState('')
    const [message, setMessage] = useState('')
    const [submitted, setSubmitted] = useState(false)
    const [processing, setProcessing] = useState(false)
  
    const [error, setError] = useState(false)
    const handleSubmit = async e => {
      // if (!fname||!lname || !email || !message || !phone) {
      //   setError(true)
      //   return
      // }
  
      // try {
      //   setProcessing(true)
  
      //   const formdata = new FormData()
      //   formdata.append('name', fname+' '+lname)

      //   formdata.append('email', email)
  
      //   formdata.append('phone', phone)
  
      //   formdata.append('message', message)
  
      //   fetch(live, {
      //     method: 'POST',
      //     body: formdata
      //   })
      //     .then(resp => resp.json())
      //     .then(res => mailRes(res))
      // } catch (error) {
      //   if (error) {
      //     setProcessing(false)
      //     alert('Something went wrong, please try again later')
      //   }
      // }
    }
    const mailRes = response => {
      if (response && response.detail) {
        setProcessing(false)
        setSubmitted(true)
        setLName('')
        setFName('')

        setMessage('')
        setEmail('')
        setPhone('')
        setTimeout(() => {
          setSubmitted(false)
        }, 3000)
      }
    }
   
  
    // AOS Intilization using UseEffect();
    useEffect(() => {
      AOS.init()
    }, [])
  
    return (




    
    <Grid container style={{minHeight:'100vh',}}>




<Grid container className='contact-left-pannel' sm={6} xs={12}>

<Grid item alignContent={'center'} sm={12} xs={12}>
    <Typography style={{color:'white'}}  className='home_top_txt1'>
    CONTACT

    </Typography> 
    </Grid>
   
<Grid item sm={12} xs={12}>
    <Typography  style={{color:'white'}} className='home_top_txt2'>
    Get in Touch!



  </Typography>
</Grid>

<Grid item sm={12} xs={12}>
    <Typography  style={{color:'white'}} className='home_top_txt3'>
    Got a question about GT Cyber? Use the form to contact us.
    </Typography>
    
    </Grid>

    <Grid item  textAlign='center'sm={12} xs={12}>
    <img style={{height:150}}  src={require('../../images/contact.gif')} />
    
    </Grid>
</Grid>
<Grid container justifyContent={'center'} alignContent={'center'} sm={6} xs={12}>
<div className='career_form_container'  data-aos='fade-left'>
          <h1>Send Us a Message</h1>
          <div className='career_form_container_inputbox'>
            <label>First Name*</label>
            {error && fname.length == 0 ? (
              <p className='error-message'>Name is Required</p>
            ) : (
              ''
            )}
            <input
              onChange={e => setFName(e.target.value)}
              value={fname}
              placeholder='Enter Your First Name'
            />
          </div>
          <div className='career_form_container_inputbox'>
            <label>Last Name*</label>
            {error && lname.length == 0 ? (
              <p className='error-message'>Last Name is Required</p>
            ) : (
              ''
            )}
            <input
              onChange={e => setLName(e.target.value)}
              value={lname}
              placeholder='Enter Your Last Name'
            />
          </div>
          <div className='career_form_container_inputbox'>
            <label>
              Email Address*{' '}
              <p
                style={{
                  display: 'inline',
                  fontSize: '.7rem',
                  color: 'var(--btn-gray)'
                }}
              >
                (Your email address will not be published.)
              </p>
            </label>

            {error && email.length == 0 ? (
              <p className='error-message'>Email is Required</p>
            ) : (
              ''
            )}

            <input
              type='email'
              onChange={e => setEmail(e.target.value)}
              value={email}
              placeholder='Enter Your Email Address'
            />
          </div>
          <div className='career_form_container_inputbox'>
            <label>Phone*</label>
            {error && phone.length == 0 ? (
              <p className='error-message'>Phone number is Required</p>
            ) : (
              ''
            )}

            <input
              type='number'
              onChange={e => setPhone(e.target.value)}
              value={phone}
              placeholder='Enter Your Phone Number'
            />
          </div>
          <div className='career_form_container_inputbox'>
            <label>Message*</label>
            {error && message.length == 0 ? (
              <p className='error-message'>Message is Required</p>
            ) : (
              ''
            )}

            <textarea
              onChange={e => setMessage(e.target.value)}
              value={message}
              rows='4'
              placeholder='Enter Your Message'
            />
          </div>

          <button
            type='submit'
            onClick={() => handleSubmit()}
            className='career_button'
          >
            {processing
              ? 'Submitting'
              : submitted && !processing
              ? 'Submitted'
              : 'Submit'}
          </button>
        </div>
    
</Grid>

    </Grid>
  )
}
