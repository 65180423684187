import { Button, Container, Grid, Paper, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import Expert from '../common/expert/expert'
import './about_us.css'
import Testimonials from './Testimonials'
import 'aos/dist/aos.css';
import Aos from 'aos';
import Opinion from './opinion'
export default function About_us() {
    
   
  useEffect(() => {
    Aos.init()
   }, [])
   
    
    
    
    
    
    

    const data=[{
    number:'10+',
    head:'Years in the industry'    
    },
    {
        number:'80%',
        head:'Audit time saved'    
        },
        {
            number:'90%',
            head:'App deploy time saved'    
            },
            {
                number:'30+',
                head:'Supported vendors'    
                },
                {
                    number:'25+',
                    head:'Industry awards'    
                    },]


      const data_testimonials=[
        {
        img:'testi1.svg',
        company:'Global 500 Service Provider',
        quote:"I've been doing this a long time, and Forward is one of the very few transformational products I’ve seen over the many years.",
        

      },
      {
        img:'testi2.svg',
        company:'Global 500 Service Provider',
        quote:"I've been doing this a long time, and Forward is one of the very few transformational products I’ve seen over the many years.",
        
      },
      {
        img:'testi3.svg',
        company:'Global 500 Service Provider',
        quote:"I've been doing this a long time, and Forward is one of the very few transformational products I’ve seen over the many years.",
        
      },
      {
        img:'testi4.svg',
        company:'Global 500 Service Provider',
        quote:"I've been doing this a long time, and Forward is one of the very few transformational products I’ve seen over the many years.",
        
      },
    ]              
  return (
  
  
<Grid container>

<Container className='home_top_main_comp'>


    
    <Grid item alignContent={'center'} sm={12} xs={12}>
    <Typography data-aos='fade-right'  className='home_top_txt1'>
    ABOUT US


    </Typography> 
    </Grid>
   
<Grid item sm={12} xs={12}>
    <Typography data-aos='fade-right'  className='home_top_txt2'>
    About GT Cyber


  </Typography>
</Grid>

<Grid item sm={12} xs={12}>
    <Typography data-aos='fade-right'  className='home_top_txt3'>
    Transforming  Cyber Security  to be more reliable, agile, secure and  process oriented 
    </Typography>
    
    </Grid>
<Grid item data-aos='fade-right'  container={{paddingBottom:'5vh'}} sm={12} xs={12}>

<Grid  item textAlign={'center'} style={{paddingBottom:'3vh'}} sm={6} xs={12}>
<Button   style={{width:'80%'}} onClick={()=>window.location.href='/contact-us'}  className='home_below_ready_btn1'>
       Join our team
    </Button>  

    
    </Grid>
    <Grid item sm={6} xs={12}>
    <Button  style={{width:'80%'}} onClick={()=>window.location.href='/contact-us'} className='home_below_ready_btn2'>
       Read customer reviews
    </Button> 
    
    </Grid>


   
    </Grid>
   
 
    


</Container>
<Opinion />

<Grid container style={{padding:'10vh 2vw',}} >

<Grid item  sm={6} xs={12}>
<img data-aos='zoom-in' src={require('../../images/about.jpg')} className='about_img' />
</Grid>
<Grid container style={{boxShadow:'2px 6px 15px 12px rgba(238,0,0,.25)',borderTopRightRadius:20,borderBottomRightRadius:20}} sm={6} xs={12}>

<Grid container style={{background:'#eee',borderTopRightRadius:20}} alignContent={'flex-start'} sm={12} xs={12}>
            <Grid item sm={12} xs={12}>
                <Typography data-aos='fade-right' className='about_mission_head'>
                Our Mission: Accelerate Adoption of Secure Technologies



                </Typography>
            </Grid>
            <Grid item sm={12} xs={12}>
                <Typography data-aos='fade-right' style={{paddingBottom:20}} className='about_mission__desc'>
                Collaborate with innovators to provide real-world, standards-based cybersecurity capabilities that address business needs. 
                                </Typography>
            </Grid>
        </Grid>
        <Grid container style={{background:'var(--color1)',borderBottomRightRadius:20}} alignContent={'flex-start'} sm={12} xs={12}>
            <Grid item sm={12} xs={12}>
                <Typography data-aos='fade-right' className='about_vision_head'>
                Our Vision: Advance Cybersecurity


                </Typography>
            </Grid>
            <Grid item sm={12} xs={12}>
                <Typography data-aos='fade-right' style={{paddingBottom:20}} className='about_vision__desc'>
                A secure cyber infrastructure that inspires technological innovation and fosters economic growth. 
                                </Typography>
            </Grid>
        </Grid>
    
</Grid>


<Grid container style={{padding:'10vh 2vh'}} justifyContent={'center'} sm={12} xs={12}>
{data.map((val,idx)=>
    <Grid data-aos='zoom-in-up' key={idx} item sm={2} xs={6}>

<Grid item sm={12} xs={12}>
  <Typography className='about_records_num'>{val.number}</Typography>  
</Grid>
<Grid item sm={12} xs={12}>
  <Typography className='about_records_head'>{val.head}</Typography>  
</Grid>

</Grid>)}

</Grid>
<Grid container style={{padding:'0px 2vw'}} sm={12} xs={12}>

<Grid item textAlign={'left'} sm={12} xs={12}>
    <Typography  data-aos='fade-right' className='home_top_txt1'>
    TESTIMONIALS


    </Typography> 
    </Grid>
   
<Grid textAlign={'left'} item sm={12} xs={12}>
    <Typography  data-aos='fade-right' className='home_top_txt2'>
    Don’t take our word, see what our customers say



  </Typography>
</Grid>    





<Grid textAlign={'-webkit-center'} item sm={12} xs={12}>

<Testimonials data={data_testimonials}  />
</Grid>



</Grid>

    </Grid>


<Expert />

</Grid>

  )
}
