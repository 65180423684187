import React ,{useEffect} from 'react'
import Slider from 'react-slick'
import './Testimonial.css'
import { HiArrowSmRight , HiArrowSmLeft} from "react-icons/hi";
import AOS from 'aos';
import 'aos/dist/aos.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    
    <HiArrowSmRight
    className='nextArrow'
    size={20}
      onClick={onClick}
    /> 
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    
    <HiArrowSmLeft
    className='prevArrow'
    size={20}
      onClick={onClick}
    /> 
  );
}




const Testimonials = (props) => {
  useEffect(()=>{
    AOS.init();
  },[])
  const {data}=props
  var settings = {
    swipeable: true,
    // dots: true,
    infinite: true,
    autoplay:true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 786,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }
  return (
    <div className='Test-grid'>
      {/* <h1 className='heading'>Testimonial</h1> */}
      <Slider {...settings}>
      {data.map((val,idx)=>
      <div key={idx} className='TC-grid'>
      <div className='TC-grid-1'>
        <img src={require(`../../images/${val.img}`)} alt='Person image' data-aos='fade-right'  />
      </div>
      <div className='TC-grid-2' data-aos='fade-left'>
        <div className='TC-quote'>
          <p>"{val.quote}"</p>
        </div>
        <div className='TC-person'>
          <h1>{val.company}</h1>
          <h3>{"GT Cyber Customer"}</h3>
          {/* <img src={require('../Assets/google.png')} alt='/' /> */}
        </div>
      </div>
    </div>)}
      </Slider>
    </div>
  )
}

export default Testimonials
