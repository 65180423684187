import '../network-security/network-security.css'

import { Button, Container, Grid, Paper, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import Expert from '../../common/expert/expert'
import 'aos/dist/aos.css';
import Aos from 'aos';

export default function Digitalrisk() {
    useEffect(() => {
        Aos.init()
       }, [])
       


        
       
       
       
       
       
       
       
        
       const digital_data=[
        {
        head:"Compliance Consulting – ISO27001, ISMS, PCI-DSS, SOXITGC, NIST",
        data:'',
        img:require('../../../images/network_security.webp')
      },{
        head:"Risk Management- Cyber Risk, OT, TPR, ASD, NESA, NIST, CSA",
        data:'',
        img:require('../../../images/management.jpg')
      },
      {
        head:"India CERT Compliance",
        data:'',
        img:require('../../../images/security.jpg')
      },
      {
        head:"ZTA Readiness & Assessment",
        data:'',
        img:require('../../../images/network_security.webp')
      },
      {
        head:" Infrastructure Security Testing",
        data:'',
        img:require('../../../images/management.jpg')
      },
      {
        head:"Virtual-CISO Service",
        data:'',
        img:require('../../../images/security.jpg')
      },
      
    
    
    ]
      
  return (


<Grid container>

<Container className='home_top_main_comp'>

 <Grid container>   
<Grid container data-aos='fade-right' sm={12} xs={12}>

    <Grid item sm={2} xs={2}>
<img src={require('../../../images/logo.png')} className='products_N_S_logo' />
    </Grid>
    <Grid item alignContent={'center'} sm={10} xs={10}>
    <Typography  className='home_top_txt1'>
    DIGITAL RISK & COMPLIANCE


    </Typography> 
    </Grid>
   
</Grid>
<Grid item sm={12} xs={12}>
    <Typography data-aos='fade-right'  className='home_top_txt2'>
    GT Cyber 
for Digital risk and compliance
  </Typography>
</Grid>

<Grid item sm={12} xs={12}>
    <Typography data-aos='fade-right' className='home_top_txt3'>
    Delivering complete hybrid network and cloud connectivity visibility, strengthens end-to-end security, and reduces cloud operations costs</Typography>
</Grid>

<Grid item style={{paddingBottom:'5vh'}} sm={12} xs={12}>
<Button data-aos='fade-right' onClick={()=>window.location.href='/contact-us'}  className='home_below_ready_btn1'>
       Request a demo
    </Button>  
    </Grid>
    </Grid>
    
    {digital_data.map((val,idx)=>
    idx%2===0?<Grid data-aos='zoom-in-down' container style={{padding:'5vh 0px'}} >

    <Paper className='products_N_S_ppr'>
            
    <Grid container height={'100%'} sm={12} xs={12}>
    
            <Grid container  sm={6} xs={12}>
    <img  src={val.img}  className='products_N_S_ppr_img' />
            </Grid>
            <Grid container alignContent={'flex-start'} sm={6} xs={12}>
                <Grid item sm={12} xs={12}>
                    <Typography className='products_N_S_ppr_head'>
                    {val.head}
    
                    </Typography>
                </Grid>
                <Grid item sm={12} xs={12}>
                    <Typography className='products_N_S_ppr_desc'>
                    Know your attack surface and device “blast radius” in an instant to speed remediation  
                    </Typography>
                </Grid>
            </Grid>
            </Grid>
            </Paper>
        </Grid>:<Grid data-aos='zoom-in-down' container style={{padding:'5vh 0px'}} >

<Paper className='products_N_S_ppr'>
        
<Grid container height={'100%'} sm={12} xs={12}>

       
        <Grid container className='products_N_S_cmp' alignContent={'flex-start'} sm={6} xs={12}>
            <Grid item sm={12} xs={12}>
                <Typography className='products_N_S_ppr_head2'>
                {val.head}


                </Typography>
            </Grid>
            <Grid item sm={12} xs={12}>
                <Typography className='products_N_S_ppr_desc2'>
                Know your network is vulnerability free with mathematical certainty  
                </Typography>
            </Grid>
        </Grid>
        <Grid container  sm={6} xs={12}>
<img src={val.img}  className='products_N_S_ppr_img2' />
        </Grid>
        
        </Grid>
        </Paper>
    </Grid>
    )}

   


</Container>



<Expert />
</Grid>

  )
}
